import {adminApi} from "../../aaxios";
import {useState} from "react";
import {UserPermission} from "../../infra/permission/permission";
import {clearToken, setToken} from "../../infra/token/token_storage";

// 유저 이름, 권한을 받아서 권한 체크
async function checkPermission(id, requiredPermission) {
    try {
        const response = await adminApi.post("/admin/compare_user_permission", {
            username: id,
            required_permission: requiredPermission
        })
        return response.data.data  // boolean 값
    } catch (error) {
        console.error("유저 권한 가져오기 실패", error)
        return false
    }
}

// 유저 이름, 비밀번호를 받아서 토큰 생성
async function generateToken(id, password) {
    try {
        const response = await adminApi.post("/token", {
            username: id,
            password: password
        })
        return response.data['data']
    } catch (error) {
        console.error("토큰 생성 실패", error)
        return null
    }
}

export default function useInternalLogin() {
    const [id, setId] = useState("")
    const [password, setPassword] = useState("")
    
    async function handleLogin() {
        clearToken()
        const permission = await checkPermission(id, UserPermission.SUPERVISOR)
        const tokenData = await generateToken(id, password)
        
        if (!tokenData) {
            alert("아이디 또는 비밀번호가 잘못되었습니다.")
            return
        }
        if (!permission) {
            alert("내부 관리자 아이디로 로그인 해주세요.")
            return
        }
        
        setToken(tokenData)
        window.location.href = "/internal/main"
    }

    return {
        id,
        setId,
        password,
        setPassword,
        handleLogin
    }
}