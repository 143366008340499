import {adminApi, authApi} from "../aaxios";
import {useEffect, useState} from "react";

async function uploadPdfRequest(formData, userPermission) {
    try {
        let baseUrl = '/admin/pdf/upload'
        if (userPermission === 'admin') {
            baseUrl = '/admin/pdf/upload?access=admin'
        }
        console.log('baseUrl', baseUrl)

        const res = await authApi.post(baseUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        return res['data']
    } catch (e) {
        alert("파일 업로드 실패")
    }
}

async function listPdfReq(userPermission) {
    try {
        let url = `/admin/pdf/list?page=${1}&page_size=${10}`
        if (userPermission === 'admin') {
            url = `/admin/pdf/list?access=admin&page=${1}&page_size=${10}`
        }
        console.log('userPermission', userPermission)
        console.log('listPdfReq url', url)
        const res = await authApi.get(url)
        return res['data']['data']
    } catch (e) {
    }
}

async function deletePdfRequest(pdf) {
    try {
        const url = `/admin/pdf/${pdf.id}`
        const res = await authApi.delete(url)
        return res['data']
    } catch (e) {
        alert("파일 삭제 실패")
    }
}

async function getServiceFileRequest(serviceFileId) {
    try {
        const url = `/admin/pdf/${serviceFileId}/status`
        const res = await authApi.get(url)
        return res['data']['data']
    } catch (e) {
        alert("파일 단건 조회 실패")
    }
}


export default function useLoadPdf() {
    const [pdfList, setPdfList] = useState([])
    const [userPermission, setUserPermission] = useState('')

    // userPermission 메모리 누수 방지를 위해 코드 timeout 추가
    useEffect(() => {
        setTimeout(() => {
            listPdfReq(userPermission).then((data) => {
                const newList = [...data['page']]
                setPdfList(newList)
                watchPdfStatus(newList)
            })
        }, 1000)
    }, [userPermission]);


    function watchPdfStatus(dataList) {
        dataList.map(pdf => {
            if (pdf['status'] === 'processing' || pdf['status'] === 'uploaded') {
                const uploadFileStatusInterval = setInterval(async () => {
                    const newRes = await getServiceFileRequest(pdf.id)
                    setPdfList(prevFiles => {
                        return prevFiles.map((file) => {
                            return file.id === pdf.id ? newRes : file
                        })
                    })
                    if (newRes['status'] !== "processing") {
                        clearInterval(uploadFileStatusInterval);
                    }
                }, 5000)
            }
        })
    }


    async function uploadPdf(formData) {
        const res = await uploadPdfRequest(formData, userPermission)
        if (res.ok) {
            const newList = [...res['data'], ...pdfList]
            setPdfList(newList)
            watchPdfStatus(newList)
            return {
                ok: true
            }
        }
        return {
            ok: false
        }
    }

    async function deletePdf(pdf) {
        const res = await deletePdfRequest(pdf)
        if (res.ok) {
            setPdfList(pdfList.filter(p => p.id !== pdf.id))
        }
    }

    return {
        pdfList,
        setUserPermission,
        uploadPdf,
        deletePdf
    }
}