import React, {useEffect, useState} from 'react';
import '../css/Home.css';
import FileUploadComponent from '../Components/PdfUploader';
import {adminApi, authApi} from "../aaxios"
import useEnv from "../useEnv";
import LoginRoute from "../container/LoginRoute";
import FAQContainer from "../container/home/FAQContainer";
import PDFContainer from "../container/home/PDFContainer";


const SuggestQuestion = () => {
    // faq 보여주는 함수
    const [inputs, setInputs] = useState(['']);

    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                const response = await adminApi.get('/faq');
                console.log('faq response >>', response.data);
                setInputs(response.data.length > 0 ? response.data : ['']);
            } catch (error) {
                console.error('FAQ 데이터 로딩 에러 >>', error);
            }
        };
        fetchFAQ();
    }, []);

    const handleChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index] = {
            id: undefined,
            faq: event.target.value
        };
        setInputs(newInputs);
    };

    const addInput = () => {
        setInputs([...inputs, '']);
    };

    const removeInput = async (faqId) => {
        const userConfirmed = window.confirm("정말로 삭제하시겠습니까?");
        if (!userConfirmed) return

        if (faqId) {
            const response = await authApi.delete(`/faq`, {
                data: {'faqId': faqId}
            });

            if (response.status === 200) {
                // 서버에서 데이터가 성공적으로 삭제되면 클라이언트 상의 데이터도 삭제
                const newInputs = inputs.filter(item => item.id !== faqId);
                setInputs(newInputs);
            } else {
                console.error('Failed to delete the input on the server >>', await response);
            }
        } else {
            console.log('faq가 빈 문자열이므로 서버에 삭제를 요청하지 않음(클라이언트만 삭제)');
            // 클라이언트 상의 데이터만 삭제
            const newInputs = inputs.filter(item => item.id !== faqId);
            setInputs(newInputs);
        }
    };

    const handleSubmit = async () => {
        console.log('faq inputs >>', inputs);
        let requestFaq;
        requestFaq = inputs.map(item => item.faq);
        try {
            const response = await authApi.put('/faq', {
                faq: requestFaq,
            });
            alert('자주 찾는 질문이 성공적으로 제출되었습니다.');
        } catch (error) {
            console.error('질문 제출 에러 >>', error);
            alert('빈 값을 넣을 수 없습니다.');
        }
    };

    return (
        <div className='suggest-section'>
            {inputs.map((input, index) => (
                <div key={input.id} style={{marginBottom: '10px', display: 'flex', justifyContent: 'center'}}>
                    <input
                        key={index}
                        value={input.faq}
                        onChange={(e) => handleChange(index, e)}
                        placeholder='자주 찾는 질문을 입력하세요'
                    />
                    <button onClick={() => removeInput(input.id)} style={{
                        marginLeft: '10px',
                        width: '45px',
                        height: '40px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '10px',
                        cursor: 'pointer'
                    }}>
                        삭제
                    </button>
                </div>
            ))}
            <div className='suggest-btn'>
                <button
                    onClick={addInput}
                    style={{
                        backgroundColor: 'skyblue',
                        fontSize: '25px',
                        borderRadius: '100px',
                        width: '50px',
                        height: '50px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    +
                </button>
                {inputs.length === 0 && (<button
                    onClick={handleSubmit}
                    style={{backgroundColor: '#3ebb3d', color: 'white', cursor: 'pointer'}}
                >
                    등록
                </button>)}
                {inputs.length > 0 && (<button
                    onClick={handleSubmit}
                    style={{backgroundColor: '#3ebb3d', color: 'white', cursor: 'pointer'}}
                >
                    업데이트
                </button>)}
            </div>
        </div>
    );
}

const Home = () => {
    const [isChatCopied, setIsChatCopied] = useState(false);
    const [isAdminCopied, setIsAdminCopied] = useState(false);

    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [pdfFileNames, setPdfFileNames] = useState([]);
    const [filesEmbeddingStatus, setFilesEmbeddingStatus] = useState([]);
    const [lastClicked, setLastClicked] = useState(0);
    const [showPopup, setShowPopup] = useState(false); // 이미지 팝업 표시 여부
    const [currentImageUrl, setCurrentImageUrl] = useState('');  // 현재 팝업에 표시할 이미지 URL

    const {
        chatBotHomeUrl,
        chatbotAdminHomeUrl
    } = useEnv()


    const [pdfAlreadyRegistered, setPdfAlreadyRegistered] = useState(
        !!pdfFileNames && pdfFileNames.length > 0
    );

    // 파일 업로드 상태 관리
    const handleUploadSuccess = (isSuccess, newFileNames) => {
        if (isSuccess) {
            setPdfFileNames(prevNames => [...prevNames, ...newFileNames]);
            setFilesEmbeddingStatus(prevStatuses => [
                ...prevStatuses,
                ...newFileNames.map(() => 'STARTED') // 새 파일에 대한 상태를 'STARTED'로 설정
            ]);
            console.log('emb. status update >>', filesEmbeddingStatus)
            localStorage.setItem('pdf_file_name', JSON.stringify([...pdfFileNames, ...newFileNames]));
            setUploadSuccess(true);
            setPdfAlreadyRegistered(true);
        }
    };

    // 서버에 파일 삭제 컨트롤러
    const handleRemovePdfFile = async (index) => {
        const fileNameToDelete = pdfFileNames[index];

        const isConfirmed = window.confirm(
            `다음 파일을 정말로 삭제하시겠습니까?\n'${fileNameToDelete}'`
        );

        if (!isConfirmed) {
            return; // 삭제 확인 중 취소를 눌렀을 때
        }

        // 서버에 파일 삭제 요청
        try {
            const response = await adminApi.delete(`/pdf/file/${fileNameToDelete}/`); // 서버에 저장된 pdf 파일 삭제 API 적용
            if (response.status === 200) {
                // console.log('File is successfully deleted >>', fileNameToDelete);

                // 로컬 상태에서도 파일 및 해당 파일 상태 삭제
                const updatedFiles = [...pdfFileNames];
                const updatedStatuses = [...filesEmbeddingStatus];
                updatedFiles.splice(index, 1);
                updatedStatuses.splice(index, 1);
                setPdfFileNames(updatedFiles);
                setFilesEmbeddingStatus(updatedStatuses);
                localStorage.setItem('pdf_file_name', JSON.stringify(updatedFiles));

                alert('파일이 성공적으로 삭제되었습니다.');
            } else {
                throw new Error('Failed to delete the file from the server.');
            }
        } catch (error) {
            console.error('Error deleting file >>', error);
            alert('파일 삭제 중 오류가 발생했습니다. 나중에 다시 시도하세요.');
        }
    };

    // 챗봇, 관리자 페이지 URL 복사 기능 컨트롤러
    const handleCopyClipBoard = async (text, type) => {
        try {
            await navigator.clipboard.writeText(text);
            if (type === 'chat') {
                setIsChatCopied(true);
                setTimeout(() => setIsChatCopied(false), 3000);
            } else if (type === 'admin') {
                setIsAdminCopied(true);
                setTimeout(() => setIsAdminCopied(false), 3000);
            }
        } catch (err) {
            console.log('copy error >>', err);
            if (type === 'chat') {
                setIsChatCopied(false);
            } else if (type === 'admin') {
                setIsAdminCopied(false);
            }
        }
    };

    // 이미지 클릭 시 팝업 표시/숨김 토글
    const handleImageClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);  // 클릭된 이미지 URL 설정
        setShowPopup(true);            // 팝업 표시
    };
    
    // vector_router.py에 /pdf/ 엔드포인트가 존재하지 않은데 코드가 있음
    // useEffect(() => {
    //     adminApi.get(`/pdf/`)
    //         .then(response => {
    //             if (response.data && response.data.result.length > 0) {
    //                 const filesOnServer = response.data.result.map(file => file.file_name);
    //                 const statuses = response.data.result.map(file => file.status);
    //                 setPdfFileNames(filesOnServer);
    //                 setFilesEmbeddingStatus(statuses);
    //                 setUploadSuccess(true);
    //                 setPdfAlreadyRegistered(true);
    //             } else {
    //                 setPdfFileNames([]);
    //                 setFilesEmbeddingStatus([]);
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error from fetching PDF file names & embedding status >>', error);
    //             setPdfFileNames([]);
    //             setFilesEmbeddingStatus([]);
    //         });


    // }, []);

    // vector_router.py에 /pdf/ 엔드포인트가 존재하지 않은데 코드가 있음
    // useEffect(() => {
    //     // 로그 출력용 시간 계산
    //     const now = new Date();
    //     const hours = ('0' + now.getHours()).slice(-2);
    //     const minutes = ('0' + now.getMinutes()).slice(-2);
    //     const seconds = ('0' + now.getSeconds()).slice(-2);
    //     const timeString = hours + ':' + minutes + ':' + seconds;

    //     const autoCheckStatus = async () => {
    //         await adminApi.get('/pdf/')
    //             .then((response) => {
    //                 const fileNames = response.data.result.map(file => file.file_name);
    //                 const newStatuses = response.data.result.map(file => file.status);
    //                 console.log('Fetched statuses >>', newStatuses, timeString);
    //                 setPdfFileNames(fileNames);
    //                 setFilesEmbeddingStatus(newStatuses);
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching data >>', error);
    //             });
    //     };

    //     if (filesEmbeddingStatus.includes('STARTED')) {
    //         const intervalId = setInterval(() => {
    //             autoCheckStatus();
    //         }, 5000);

    //         return () => clearInterval(intervalId);
    //     }
    //     console.log('filesEmbeddingStatus >>', filesEmbeddingStatus);
    // }, [filesEmbeddingStatus]);

    return (
        <LoginRoute>
            <div className='wrap'>
                <header className='msger-header'>
                    <div className='msger-header-title'>
                        <div className='header-logo-frame'>
                            <img src='/img/viewbeauty_01.svg' alt="Logo"></img>
                        </div>
                    </div>
                </header>
                {/* 가이드 아이콘 클릭 시 뜨는 팝업 이미지 */}
                {showPopup && (
                    <div className="image-popup-overlay" onClick={() => setShowPopup(false)} style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div className="image-popup-content" style={{
                            width: '430px',
                            position: 'relative',
                            padding: '20px',
                            background: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}>
                            <img
                                src={currentImageUrl}
                                alt="Popup"
                                style={{maxWidth: '100%', maxHeight: '100%'}}
                            />
                            <p>닫기</p>
                        </div>
                    </div>
                )}

                <PDFContainer access="guest" handleImageClick={handleImageClick}/>
                <FAQContainer handleImageClick={handleImageClick}/>

                <div className='home-container'>
                    <p className='section-title'>챗봇</p>
                    <button className='link-btn' onClick={() => window.open(chatBotHomeUrl)}>챗봇 바로가기</button>
                    <button className='copy-btn'
                            onClick={() => handleCopyClipBoard(chatBotHomeUrl, 'chat')}>복사
                    </button>
                    {isChatCopied && <p style={{color: 'green'}}>복사되었습니다.</p>}
                </div>

                <div className='home-container'>
                    <p className='section-title'>챗봇 질문 관리 페이지</p>
                    <button className='link-btn' onClick={() => window.open(chatbotAdminHomeUrl)}>챗봇 질문
                        관리 페이지 바로가기
                    </button>
                    <button className='copy-btn'
                            onClick={() => handleCopyClipBoard(chatbotAdminHomeUrl, 'admin')}>복사
                    </button>
                    {isAdminCopied && <p style={{color: 'green'}}>복사되었습니다.</p>}
                </div>
            </div>
        </LoginRoute>
    );
}

export default Home;