import React from 'react';
import styles from '../css/InternalLogin.module.css';
import useInternalLogin from '../hooks/internal/useInternalLogin';

export default function InternalLogin() {
    const {id, setId, password, setPassword, handleLogin} = useInternalLogin();

    return (
        <>
            <div className={styles['login-container']}>

                <div className={styles['login-box-container']}>
                    {/* 헤더 */}
                    <div className={styles['login-header-container']}>
                        <img src="/img/viewbeauty_01.svg" alt="logo" className={styles.logo}/>
                        <h1>내부 관리자 페이지</h1>
                    </div>

                    {/* 로그인 폼 */}
                    <form className={styles['login-form']} 
                        onSubmit={(e) => { 
                            e.preventDefault(); // 기본 폼 제출 동작 방지
                            handleLogin();
                        }}
                    >
                        <div className={styles['login-input-container']}>
                            {/* 아이디 */}
                            <label className={styles['input-label']}><b>관리자 ID</b></label>
                            <input 
                                className={`${styles['text-input']} ${styles['internal-input']}`}
                                type="text" 
                                value={id} 
                                onChange={(event) => setId(event.target.value)} 
                            />
                            {/* 비밀번호 */}
                            <label className={styles['input-label']}><b>password</b></label>
                            <input 
                                className={`${styles['text-input']} ${styles['internal-input']}`}
                                type="password" 
                                value={password} 
                                onChange={(event) => setPassword(event.target.value)} 
                            />
                        </div>
                        <div className={styles['login-button-container']}>
                            <button type="submit">로그인</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
