import React, {useEffect} from "react";
import "../../css/InternalNav.css";

export function InternalNavItem(props) {
    const {onClick, id, label, status} = props
    let bgColor = 'var(--view-03, #7C2B84)'
    if (status === id) {
        bgColor = '#9E49A6'
    }

    return (
        <div className='table-nav-item-text' id={id}
             style={{background: bgColor}}
             onClick={(e) => {
                 e.stopPropagation()
                 onClick && onClick()
             }}>{label}
        </div>
    )
}


export default function InternalNav(props) {
    const {items, onClick, status} = props

    return (
        <div className="table-nav-container">
            <div className="table-nav-item">
                {items.map((item, index) => {
                    return (
                        <InternalNavItem {...item}
                        onClick={() => {
                            onClick && onClick(item)
                        }}
                        status={status}
                        key={index+""+status}
                        />
                    )
                })}
            </div>
        </div>
    )

}