import React, {useState} from 'react';
import '../App.css';
import InternalLoginRoute from "../container/InternalLoginRoute";
import PageNavigation from "../Components/main/PageNavigation";
import useLoadAdminChatList from "../hooks/internal/useLoadAdminChatList";
import "../css/InternalMain.css";
import InternalNav from "../Components/internalMain/InternalNav";
import InternalTable from "../Components/internalMain/InternalTable";
import InternalChatEditor from "../Components/internalMain/ChatEditor";
import useEnv from "../useEnv.js";
import PDFContainer from "../container/home/PDFContainer";

const TABLE_HEADER_ITEMS = [
    {
        label: '전체',
        id: 'all',
    },
    {
        label: '미확인',
        id: 'check',
    },
    {
        label: '수정 필요',
        id: 'edit',
    },
    {
        label: '검토 완료',
        id: 'done',
    },
    {
        label: '관리자 작성',
        id: 'admin_edit',
    }
]

export default function InternalMainPage(props) {
    const {
        chatBotHomeUrl
    } = useEnv()

    const {
        dataList,
        status,
        setStatus,
        onEditChat,
        onCloseChatEdit,
        selectedChat,
        onChangePage,
        dispatchRefresh,
        paging
    } = useLoadAdminChatList()


    function onClickTabNav(props) {
        const {id} = props
        setStatus(id)
    }

    const [currentImageUrl, setCurrentImageUrl] = useState('');  // 현재 팝업에 표시할 이미지 URL
    const [showPopup, setShowPopup] = useState(false); // 이미지 팝업 표시 여부

    const handleImageClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);  // 클릭된 이미지 URL 설정
        setShowPopup(true);            // 팝업 표시
    };
    return(
        <InternalLoginRoute>
            <div className="chat-management-container">
                <div className="chat-management-header">
                    <div className="chat-management-header-title">내부 관리자 용 메세지 관리 페이지</div>
            </div>

            <div className="chat-management-content">

                <div className="chat-management-move-button">
                    <button className="go-to-chatbot" onClick={() => {
                        window.location.href = '/internal/chatbot'
                    }}>ChatBot 이동</button>
                    <button className="go-to-login" onClick={() => {
                        window.location.href = "/internal"
                    }}>LogOut</button>
                </div>
                

                <div className="message-data-container">
                    <InternalNav
                        status={status}
                        items={TABLE_HEADER_ITEMS}
                        onClick={onClickTabNav}
                    />
                    <div className='content-container'>
                        {/* 가이드 아이콘 클릭 시 뜨는 팝업 이미지 */}
                        {showPopup && (
                            <div className="image-popup-overlay" onClick={() => setShowPopup(false)} style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div className="image-popup-content" style={{
                                    width: '430px',
                                    position: 'relative',
                                    padding: '20px',
                                    background: 'white',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}>
                                    <img
                                        src={currentImageUrl}
                                        alt="Popup"
                                        style={{maxWidth: '100%', maxHeight: '100%'}}
                                    />
                                    <p>닫기</p>
                                </div>
                            </div>
                        )}
                        <div className='pdf-container'>
                            <PDFContainer access="admin" handleImageClick={handleImageClick}/>
                        </div>

                        <div className='table-container'>
                            <div className='wrap-board' id='wrap-board-2'>
                                <InternalTable items={dataList || []}  onClick={onEditChat}/>
                                <InternalChatEditor chat={selectedChat} onRefresh={dispatchRefresh} onClose={onCloseChatEdit}/>
                            </div>

                            <div className='page_number' id='page_number1'>
                                <PageNavigation paging={paging} onChangePage={onChangePage}/>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>
        </InternalLoginRoute>
    )
}