import React from "react";


export default function Loading(props) {
    const {isLoading} = props
    if (!isLoading) {
        return null
    }

    return (
        <div className="loading-overlay">
            <img src="/img/spinner_01.gif" alt="로딩중" className="loading-spinner"/>
        </div>
    )
}