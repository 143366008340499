import {toLocaleString} from "../../../infra/date/dataView";
import React from "react";

export default function InternalChatEditTab(props) {
    const {
        date,
        onClose,
        onDelete,
        onConfirm
    } = props

    function handleClose(e) {
        e.stopPropagation()
        onClose && onClose()
    }

    function handleDelete(e) {
        e.stopPropagation()
        onDelete && onDelete()
    }

    function handleConfirm(e) {
        e.stopPropagation()
        onConfirm && onConfirm()
    }

    return (
        <div className='detail_content_tap'>
            <div>
                <p>{toLocaleString(date)}</p>
            </div>
            <button className='button_01'
                    style={{background: '#63226A', color: 'white', cursor: 'pointer'}}
                    onClick={handleConfirm}>검토완료
            </button>
            <button className='button_01'
                    style={{
                        background: '#c30c0c', color: '#FFF', cursor: 'pointer'
                    }}
                    onClick={handleDelete}>삭제
            </button>
            <button className='button_01'
                    style={{cursor: 'pointer'}}
                    onClick={handleClose}>닫기
            </button>
        </div>
    )
}