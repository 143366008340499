import { React, useState, useEffect, useRef } from 'react';
import {Home, Send} from 'lucide-react';
import '../css/InternalChat.css';
import { getToken} from '../infra/token/token_storage';
import { adminApi } from '../aaxios';
import { UserPermission } from '../infra/permission/permission';
import Loading from '../Components/LoadingComponent';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

// ================== API 관련 함수 ==================

// 토큰 검증 함수
const validateToken = async (token) => {
    const response = await adminApi.post('/admin/token/validate', {
        token: token
    })
    if (response.status !== 200) {
        throw new Error('유효하지 않은 토큰');
    }
    return response.data.data;
}

// 권한 검증 함수
const validatePermission = async (username, requiredPermission) => {
    const response = await adminApi.post('/admin/compare_user_permission', {
        username: username,
        required_permission: requiredPermission
    })
    if (!response.data.data) {
        throw new Error('유저 권한 검증 중 오류 발생');
    }
    return response.data.data;
}
// 최초 1회 세션 생성
const createSession = async () => {
    const token = getToken();
    const tokenInfo = await validateToken(token.refresh);
    const response = await adminApi.post('/admin/session', {
        username: tokenInfo.sub
    });
    return response.data.data.id;
}

// 채팅방 생성
const createChat = async (sessionId, text, permissionLevel) => {
    const response = await adminApi.post(`/chat/${permissionLevel}`, {
        session_id: sessionId,
        message: text,
        query_type: 'pdf'
    });
    return response.data;
}
// 메세지 생성
const createAdminMessage = async (chatId, text, permissionLevel) => {
    const response = await adminApi.post(`/chat/${permissionLevel}/${chatId}/message`, {
        message: text,
        query_type: 'pdf'
    });
    return response.data;
}

// ================== 일반 함수 ==================

// 토큰을 통해 유저 권한 검증
const getUserPermission = async () => {
    try{
        const tokens = getToken();
        if (!tokens?.refresh) {
            throw new Error('No refresh token');
        }
        const tokenInfo = await validateToken(tokens.refresh);
        const permissionCondition = await validatePermission(tokenInfo.sub, UserPermission.SUPERVISOR);

        if (!permissionCondition) {
            window.location.href = "/internal";
        }
        console.log('유저 권한 검증 완료', permissionCondition);
    } catch (error) {
        console.error('유저 권한 검증 중 오류 발생:', error);
        window.location.href = "/internal";
    }
}
// 현재 시간 가져오기
const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();

    const formattedHours = hours > 12 ? hours - 12 : hours;
    const period = hours >= 12 ? '오후' : '오전';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${period} ${formattedHours}:${formattedMinutes}`;
}


// ================== 메인 함수 ==================
const InternalChat = () => {
    const [displayedText, setDisplayedText] = useState('찾으시는 고객의 질문을 알려주세요.');
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // 챗봇에 필요한 변수들
    const [sessionId, setSessionId] = useState('');
    const [chatId, setChatId] = useState('');
    const [answerNum, setAnswerNum] = useState(1);
    const [permissionLevel, setPermissionLevel] = useState('ADMIN'); // 임시 권한 설정
    
    // 메세지 전송
    const sendMessage = async (messageToSend = text) => {
        // 입력된 텍스트 or 함수에 전달된 메시지를 사용
        const messageContent = messageToSend.trim();
        if (!messageContent) return;

        setMessages(prevMessages => [...prevMessages, {
            sender: 'user',
            text: messageContent
        }]);
        setText('');
        setIsLoading(true); // 로딩 시작


        let response;
        if (answerNum === 1){
            response = await createChat(sessionId, messageContent, permissionLevel);
            setChatId(response.data.chat_id);
        } else {
            response = await createAdminMessage(chatId, messageContent, permissionLevel);
        }

        const bot_message = response.data.answer.message;
        setMessages(prevMessages => [...prevMessages, {
            sender: 'bot',
            text: bot_message
        }]);
        setAnswerNum(prevAnswerNum => prevAnswerNum + 1);
        setIsLoading(false); // 로딩 종료
    }

    
    // 스크롤 하단 고정
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };

    // 모바일 100vh issue 해결을 위한 스크립트
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();

        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 메세지 마운트 시 스크롤 하단 고정
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 최초 1회 유저 권한 검증
    useEffect(() => {
        getUserPermission();    // 유저 권한 검증

        if (!sessionId) {
            createSession().then(id => {
                setSessionId(id); // 세션 아이디 저장
            });
        }
    }, []);

    return (
        <div className={`chatbot-container ${isLoading ? 'blurred' : ''}`}>
            <div className="chatbot-box-container">
            <Loading isLoading={isLoading}/>
                {/* 헤더 영역 */}
                <div className="chatbot-header-container">
                    <img className="chatbot-header-logo" src="/img/viewbeauty_01.svg" alt="logo"/>
                    <div className="chatbot-header-title">내부 관리자 용 챗봇</div>
                    <button className="go-to-management-button" onClick={() => {
                        window.location.href = '/internal/main';
                    }}>관리 페이지 이동</button>
                </div>
                {/* 챗봇 컨텐츠 영역 */}
                <div className="chatbot-content-container">
                    <div className="message bot-message">{displayedText}</div>
                    {
                        messages.map((msg, index) => (
                            <div 
                                key={index}
                                className={`message ${msg.sender === 'user' ? 'user-message' : 'bot-message'}`}>
                                <ReactMarkdown remarkPlugins={[remarkBreaks]}>{msg.text}</ReactMarkdown>
                                <div className="message-time">{msg.sender === 'user' ? getCurrentTime() : ''}</div>
                            </div>
                        ))
                    }
                    <div ref={messagesEndRef}/>
                </div>
                {/* 챗봇 입력 영역 */}
                <div className="chatbot-input-container">
                    <Home className="go-to-home" onClick={() => {
                        window.location.reload();
                    }}/>
                    <input className="chatbot-input" 
                        type="text" 
                        value={text} 
                        onChange={(e) => {
                        setText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                        disabled={isLoading}
                        placeholder='무엇이든 물어보세요'
                    />
                    <div className="send-button-container"
                        onClick={() => {
                            sendMessage();
                        }}
                    >
                        <Send className="send-button" 
                            style={{ transform: 'rotate(42deg)' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}   

export default InternalChat;
