import React, { useState } from "react";
import "../../css/InternalTable.css"


export function TableItem(props) {
    let {
        index,
        id,
        onClick,
        first_message,
        status,
        created_at,
        user,
        isSelected,
    } = props

    const dateObject = new Date(created_at);
    const utcToKST = new Date(dateObject.getTime() + 9 * 60 * 60 * 1000);
    const date = utcToKST.toISOString().split('T')[0];
    const time = utcToKST.toTimeString().split(' ')[0];

    function handleClick(e) {
        e.stopPropagation()
        console.log("row 클릭")
        onClick && onClick()
    }

    function convertStatus(_status) {
        if (_status === 'unidentified') return "미확인"
        if (_status === 'confirm') return "검토완료"
        if (_status === 'deleted') return '삭제완료'
        if (_status === 'edit') return '수정 필요'
        if (_status === 'admin_edit') return '관리자 작성'
        return "미확인"
    }

    return (
        <tr 
            className={`table-body-container ${isSelected ? 'selected' : ''}`}
            key={id}
            onClick={handleClick}>
            <td className='table-td'>{index + 1}</td>
            <td className='table-td'>{first_message}</td>
            <td className='table-td'>{date}</td>
            <td className='table-td'>{time}</td>
            <td className={`table-td ${status}`}>{convertStatus(status)}</td>
            <td className='table-td'>{user.nickname ? user.nickname : 'X'}</td>
            <td className='table-td'>{user.phone_number ? user.phone_number : 'X'}</td>
        </tr>
    )
}

export default function InternalTable(props) {
    const {items, onClick} = props;
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleRowClick = (item) => {
        setSelectedIndex(item.index);
        onClick(item);
    };

    return (
        <table className='table-frame' id='TableBody'>
            <thead className="table-thead">
                <tr>
                    <th className='table-th basic'>글번호</th>
                    <th className='table-th content'>내용</th>
                    <th className='table-th basic'>날짜</th>
                    <th className='table-th basic'>시간</th>
                    <th className='table-th basic'>상태</th>
                    <th className='table-th basic'>고객성함</th>
                    <th className='table-th basic'>연락처</th>
                </tr>
            </thead>
            <tbody id='table-body'>{
                items.map((item, key) => (
                    <TableItem 
                        {...item} 
                        index={key}
                        key={key}
                        isSelected={selectedIndex === key}
                        onClick={() => handleRowClick({
                            ...item,
                            index: key
                        })}
                    />
                ))
            }</tbody>
        </table>
    )
}